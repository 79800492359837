import { Title } from '@solidjs/meta';
import { ActivityIndicator, Input, PillButton } from '@troon/ui';
import { Combobox } from '@kobalte/core/combobox';
import { Popover } from '@kobalte/core/popover';
import { IconCaretDownMd, IconCheck } from '@troon/icons';
import { createResource, createSignal, Show } from 'solid-js';
import { StyleCard } from '../_style-card';
import { gql } from '../../../../graphql';
import { cachedQuery } from '../../../../graphql/cached-get';

export default function ComboBoxPage() {
	const [input, setInput] = createSignal('');
	const [options] = createResource(
		() => input(),
		async (query: string) => {
			if (query.length < 3) {
				return [{ label: '…', options: [{ value: '', label: 'keep typing…', disabled: true }] }];
			}
			const { facilities, locations } = (await searchFacilities({ query }))?.search ?? {
				facilities: [],
				locations: [],
			};
			const items = [];
			if (locations.length) {
				items.push({
					label: 'Locations',
					options: locations.map((loc) => ({
						label: `${loc.place}, ${loc.region}`,
						value: loc.place,
						disabled: false,
					})),
				});
			}

			if (facilities.length) {
				items.push({
					label: 'Courses',
					options: facilities.map((fac) => ({
						label: fac.name,
						value: fac.slug,
						disabled: false,
					})),
				});
			}

			return items;
		},
	);

	return (
		<>
			<Title>ComboBox | Forms | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">ComboBox</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="ComboBox">
					<Combobox
						defaultFilter={() => true}
						onInputChange={(value) => setInput(value)}
						options={options.latest ?? []}
						triggerMode="focus"
						optionTextValue="label"
						optionLabel="label"
						optionValue="label"
						optionDisabled="options"
						optionGroupChildren="options"
						placeholder="Search…"
						// defaultOpen
						sameWidth
						// fitViewport
						sectionComponent={(props) => <Combobox.Section>{props.section.rawValue.label}</Combobox.Section>}
						itemComponent={(props) => (
							<Combobox.Item
								item={props.item}
								class="group flex w-full min-w-32 grow flex-nowrap items-center justify-start gap-x-2 whitespace-nowrap rounded px-2 py-1.5 font-medium outline-none transition-all duration-200 hover:bg-brand-100 active:bg-brand-100  disabled:bg-transparent disabled:opacity-50 aria-disabled:opacity-50 aria-selected:bg-brand-200 ui-highlighted:bg-brand-100"
							>
								<Combobox.ItemLabel>{props.item.rawValue.label}</Combobox.ItemLabel>
								<Combobox.ItemIndicator>✅</Combobox.ItemIndicator>
							</Combobox.Item>
						)}
					>
						<Combobox.Label>Sample</Combobox.Label>
						<Combobox.Control>
							<Combobox.Input
								as={Input}
								suffixElement={
									<Combobox.Trigger>
										<Combobox.Icon>
											<IconCaretDownMd />
										</Combobox.Icon>
									</Combobox.Trigger>
								}
							/>
						</Combobox.Control>
						<Combobox.Portal>
							<Combobox.Content class="flex w-full flex-col overflow-y-auto overscroll-contain rounded border border-neutral-300 bg-white shadow-md">
								<Show when={options.loading}>
									<ActivityIndicator />
								</Show>
								{/* <Combobox.Arrow /> */}
								<Combobox.Listbox />
							</Combobox.Content>
						</Combobox.Portal>
					</Combobox>
				</StyleCard>
				<StyleCard title="in popover">
					<Popover sameWidth>
						<Popover.Trigger as={PillButton}>Courses</Popover.Trigger>
						<Popover.Portal>
							<Popover.Content class="flex w-full flex-col rounded border border-neutral-300 bg-white p-4 shadow-md">
								<Combobox
									// defaultFilter={() => true}
									options={['Tacos', 'Burritos', 'Churros', 'Raspberries', 'Ice cream', 'Pizza'].map((l) => ({
										label: l,
										value: l,
									}))}
									triggerMode="focus"
									optionTextValue="label"
									optionLabel="label"
									optionValue="label"
									open
									defaultOpen
									sameWidth
									multiple
									itemComponent={(props) => (
										<Combobox.Item {...props} class="flex flex-row justify-start gap-2">
											<Combobox.ItemIndicator>
												<IconCheck />
											</Combobox.ItemIndicator>
											<Combobox.ItemLabel>{props.item.rawValue.label}</Combobox.ItemLabel>
										</Combobox.Item>
									)}
								>
									<Combobox.Label>Courses</Combobox.Label>
									<Combobox.Control>
										<Combobox.Input as={Input} />
									</Combobox.Control>
									<Combobox.Listbox />
								</Combobox>
							</Popover.Content>
						</Popover.Portal>
					</Popover>
				</StyleCard>
			</div>
		</>
	);
}

const query = gql(`
query searchFacilitiesStyleguide($query: String!) {
	search: searchFacilitiesV2(query: $query, includeGeocoded: false, includeLocations: true) {
		facilities {
			slug
			name
		}
		locations {
			region
			country
			place
		}
	}
}`);

const searchFacilities = cachedQuery(query);
